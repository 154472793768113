import axios from 'axios';
import store, { state } from './store/store';

const stickyHeaderEndpointWhitelist = /\/((current|session)\/(user(?!\/shops)|static|stickySessionInfo)|auth\/rest\/refresh|shop\/shopDetails)/;

/**
 * Adds an 'x-sticky' header to all requests when authenticated to ensure requests go to a single pod in k8s
 * Without this, responses might include cached (and possibly incorrect) data from a different pod
 */
async function stickySessionInterceptor(config) {
    // Need to first check for a handful of url's that we can't/don't want to even try to add x-sticky to because they will cause infinite awaiting issues
    if (stickyHeaderEndpointWhitelist.test(config.url) || !store.getters.isAuthorizedUser) return config;

    if (!state.stickySessionId) {
        await store.dispatch('requestIfIdle', ['getStickySessionId']);
    }

    return {
        ...config,
        headers: {
            ...config.headers,
            'x-sticky': state.stickySessionId,
        },
    };
}

axios.interceptors.request.use((config) => {
    if (!state.i18n?.locale) return config;
    return {
        ...config,
        headers: {
            ...config.headers,
            'Accept-Language': state.i18n?.locale,
        },
    };
});

axios.interceptors.request.use(stickySessionInterceptor);
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const isExpiredAuthToken = state.isSPA && state.auth && error.response?.status === 401;
        let responseError = error;

        if (isExpiredAuthToken) {
            // Avoid an infinite loop. Did we already retry the reqeuest?
            // Make sure this error isn't the result of an attempt to retry the request.
            const isRefreshTokenRequest = error.config?.url.includes('/auth/rest/refresh');
            const isRetryRequest = !!error.config?.isRetry;
            if (!isRefreshTokenRequest && !isRetryRequest) {
                try {
                    await store.dispatch('refreshAuth');

                    // With our refreshed access token in hand, let's try the request again.
                    return axios({
                        ...error.config,
                        headers: {
                            ...error.config?.headers,
                            Authorization: axios.defaults.headers.common.Authorization,
                        },
                        isRetry: true,
                    });
                } catch (retryError) {
                    responseError = retryError;
                }
            }

            // A failed request here likely means our tokens are expired, and the user is logged out.
            // Regardless of the cause, something very bad went wrong and we can't authenticate our user.
            // Time to clear out the session.
            store.dispatch('logout');
        }

        return Promise.reject(responseError);
    }
);

axios.defaults.baseURL = __FCO_API_URL__;
