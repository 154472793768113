import isEqual from 'lodash/isEqual';

const getExpirationKey = (storageKey) => `${storageKey}_expiresOn`;

export const StorageKey = {
    USER: 'user',
    AUTH: 'auth',
    MASQUERADE_REAL_USER: 'masqueradeRealUserAuth',
    MASQUERADE_SOURCE: 'masqueradeSource',
    CURRENT_VEHICLE: 'currentVehicle',
    PUNCHOUT: 'punchout',
    FEATURES: 'features',
    IS_COST_HIDDEN: 'isCostHidden',
    STICKY_SESSION_ID: 'stickySessionId',
};

// for punchout partners (i.e. Mitchell 1) in the non-SPA app, we may need to handle things differently (i.e. use sessionStorage instead of localStorage)
// for the non-SPA, we can determine this by checking for a punchout query param from the BE
export const isPunchoutLogin = () => Boolean(new URLSearchParams(window.location.search).get(StorageKey.PUNCHOUT));
export const isPunchout = () => Boolean(isPunchoutLogin() || sessionStorage.getItem(StorageKey.PUNCHOUT));
export const trackPunchoutSession = () => sessionStorage.setItem(StorageKey.PUNCHOUT, 1);

export const getStorage = () => (isPunchout() ? sessionStorage : localStorage);

export const hasStorageItem = (storageKey) => Object.prototype.hasOwnProperty.call(getStorage(), storageKey);

export const removeStorageItem = (storageKey) => {
    getStorage().removeItem(storageKey);
};

export const getStorageItem = (storageKey) => {
    const expirationKey = getExpirationKey(storageKey);
    const expiresOn = Number(getStorage().getItem(expirationKey));

    if (expiresOn && Date.now() >= expiresOn) {
        removeStorageItem(storageKey);
        removeStorageItem(expirationKey);
        return null;
    }

    return JSON.parse(getStorage().getItem(storageKey));
};

export const setStorageItem = (storageKey, value, expiresInMs) => {
    // Avoid an infinite loop when multiple tabs are open. Don't set this value in localStorage if it hasn't changed.
    // See the 'storage' event handler in the store for more details.
    if (isEqual(value, getStorageItem(storageKey))) return;

    const expirationKey = getExpirationKey(storageKey);

    if (expiresInMs) {
        const expiresOn = new Date();
        expiresOn.setTime(expiresOn.getTime() + expiresInMs);
        getStorage().setItem(expirationKey, expiresOn.getTime());
    } else {
        removeStorageItem(expirationKey);
    }

    getStorage().setItem(storageKey, JSON.stringify(value));
};
